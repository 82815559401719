import React, { lazy } from 'react'
import {
  Box, Heading, Parsed, Placeholder,
} from '../ui'
import { Lazy } from '../content'
import { PostContent, parsePostContent } from '../posts'

const LocationTemplate = ({
  title,
  content,
  town,
  address,
  lat,
  lng,
}) => (
  <Box as="article">
    <Parsed
      mb={4}
      as="h1"
      fontSize={[5, 6]}
      component={Heading}
    >
      {title}
    </Parsed>

    <PostContent>
      {parsePostContent(content)}
    </PostContent>

  </Box>
)

export default LocationTemplate
